import { locationPathName } from '../common/location_path_name'

const defaults = {
  controls: true,
  repeat: false,
  autostart: true,
  image: 'assets/black-bg.png',
  shelfWidget: false,
  videoTranscribeId: false,
  recommendedVideos: [],
  hideDownloadBtn: false,
  mute: true,
  aspectratio: "16:9",
  awsSrc: false
};

class VideoPlayer {
  constructor(video) {
    this.player = { ...defaults, ...video };
    this.params = {
      image: this.player.image,
      width: "100%",
      aspectratio: this.player.aspectratio,
      autostart: this.player.autostart,
      preload: "metadata",
      primary: "html5",
      controls: this.player.controls,
      mute: this.player.mute,
      repeat: this.player.repeat,
      playbackRateControls: this.player.controls,
      recommendedVideos: this.player.recommendedVideos,
      skin: {},
      logo: {},
      showControllbar: false,
      hideDownloadBtn: this.player.hideDownloadBtn,
      awsSrc: this.player.awsSrc
    };
    if (this.player.skin) {
      this.params.skin = this.player.skin
    }
    if (this.player.logo) {
      this.params.logo = this.player.logo
    }
    this.playerInstance = jwplayer(this.player.id);

    if ((locationPathName('/admin/')) && (locationPathName('/colleges/')) && (locationPathName('/edit'))) {
      this.params.displaytitle =  true;
      this.params.title =  video.title;
    };

  }

  addSkin(params) {
    this.params = Object.assign(this.params, {skin: Object.assign(this.params.skin, params)})
  }

  addLogo(params) {
    this.params = Object.assign(this.params, {logo: Object.assign(this.params.logo, params)})
  }

  initialize() {

    if (this.player.videoKey) {
      if (this.player.videoKey.indexOf('static_videos/') > -1) {
        this.params.file = `https://s3.amazonaws.com/campusreel-production/${this.player.videoKey}`;
        this.params.type = "mp4";
      } else {
        this.params.sources = this.videoSources();
        this.params.tracks = this.videoTracks();
      }
    }

    if (this.player.awsSrc) {
      this.params.file = this.player.awsSrc;
      this.params.type = "mp4";
    }

    if (this.player.shelfWidget) {
      this.params.related = {
        displayMode: 'shelfWidget'
      };
      this.params.playlist = this.videoPlaylistSources();
    }
    if (this.player.id === 'top-video-player-without-widget') {
      this.params.playlist = this.videoPlaylistSources();
    }
    this.setupPlayer();
    if (this.params.showControllbar) {
      this._showControlBar();
    }
  }

  videoSources() {
    const sources = this.player.videoSources;
    const videoKey = this.player.videoKey;
    let returningSources = [];
    Object.entries(sources).forEach(([key, value]) => {
      returningSources.push({
        label: key,
        file: `https://content.jwplatform.com/videos/${videoKey}-${value}.mp4`,
        default: key === '720p'
      });
    });

    returningSources.push({
      default: false,
      type: 'hls',
      file: `https://content.jwplatform.com/manifests/${videoKey}.m3u8`,
      label: '0'
    });

    returningSources.reverse();
    return returningSources;
  };

  videoPlaylistSources() {
    const recommended = this.player.recommendedVideos;
    let returningRecommendedSources = [];
    let videoKey;

    recommended.forEach((value) => {
      videoKey = value.key;
      let sources = [];
      let tracks = [];
      const videos = JSON.parse(value.videos);
      for (const key in videos) {
        sources.push({
          label: key,
          file: `https://content.jwplatform.com/videos/${videoKey}-${videos[key]}.mp4`,
          default: key === '720p'
        });
      }
      if (value.key) {
        tracks.push({
          file: `https://cdn.jwplayer.com/strips/${value.key}-120.vtt`,
          kind: "thumbnails"
        });
      }
      if (value.video_subtitle_id) {
        tracks.push({
          file: `https://content.jwplatform.com/tracks/${value.video_subtitle_id}.vtt`,
          label: "English"
        });
      }
      returningRecommendedSources.push({
        title: value.title,
        image: value.thumbnail,
        sources: sources,
        tracks: tracks
      });
    });
    return returningRecommendedSources;
  }

  videoTracks() {
    let tracks = [{
      file: `https://cdn.jwplayer.com/strips/${this.player.videoKey}-120.vtt`,
      kind: "thumbnails"
    }];
    if (this.player.videoTranscribeId && this.player.videoTranscribeId.length) {
      tracks.push({
        file: `https://content.jwplatform.com/tracks/${this.player.videoTranscribeId}.vtt`,
        label: "English"
      });
    };

    return tracks;
  };

  setupPlayer() {
    const data = this;
    window.trimInit = false;

    data.playerInstance.setup(data.params);
    if ((locationPathName('/admin/')) && (locationPathName('/colleges/')) && (locationPathName('/edit'))) {
      if ( data.hideDownloadBtn === false) {
        data.initializeDownloadBtn(data);
      }
    };
    if (data.playerInstance.id === 'video-player'
      || data.playerInstance.id === 'top-video-player'
      || data.playerInstance.id === 'top-video-player-without-widget')
    {
      data.playerListener();
      if (document.querySelector('.video-page-dt') || $('#top-video-player') || $('#top-video-player-without-widget')) {
        // data.initializeMinimizing();
      };
    };
    if (data.controls) {
      data.eventsForGA();
    };
    if (data.player.timeIntervals) {
      data.initTrimFunctionality();
    };

    let trimmLine = document.getElementById('trimm-line');
    if (trimmLine) {
      let trimEvent = new Event('trimEvent');
      window.trimEvent = trimEvent;

      trimmLine.addEventListener('trimEvent', function () {
        if (window.trimInit === false) {
          data.initTrimFunctionality();
        }
      }, false);
    }
  };

  initTrimFunctionality() {
    const data = this;
    window.trimInit = true;

    let playerObject = data.playerInstance;
    let timeIntervals;

    if (data.player.timeIntervals) {
      window.trimPreviewParams = data.player.timeIntervals;
    }

    let durationTag, olapsedTag, progressTag, knobTag, duration;
    let sliderTimeClone, controlbar, elapsedClone, buttonContainer, mainPlayerCount;
    let timingsArr = [];
    let startTimePoints = [];

    if (document.querySelector('.jw-slider-time')) {
      document.querySelector('.jw-icon.jw-icon-display.jw-button-color.jw-reset').style.display = 'none';
      cloneTimeControlls();
      changeTimeInfo();
    }

    let trimmLine = document.getElementById('trimm-line');
    if (trimmLine) {
      trimmLine.addEventListener('trimEvent', trimUpdate);

      trimUpdate();
    }

    playerObject.on('play', function(event) {
      document.querySelector('.jw-icon.jw-icon-display.jw-button-color.jw-reset').style.display = 'none';
      cloneTimeControlls();
      changeTimeInfo();
    });

    let elapsTime = 0;
    let progress = 0;

    playerObject.on('time', function(event) {
      olapsedTag.innerHTML = toMMSS(elapsTime);
      let pos = event.position;

      for (var i = 0; i < window.trimPreviewParams.length; i++) {
        if (pos >= window.trimPreviewParams[i][0] && pos <= window.trimPreviewParams[i][1]) {
          let intervalsSumm = 0;

          for (var s = 0; s < i; s++) {
            intervalsSumm += window.trimPreviewParams[s][1] - window.trimPreviewParams[s][0];
          }

          elapsTime = (pos - window.trimPreviewParams[i][0]) + intervalsSumm;
          progress = (100 * elapsTime) / duration;
          progressTag.style.cssText = 'width:' + progress + '%!important;';
          knobTag.style.cssText = 'left:' + progress + '%!important;';
          olapsedTag.innerHTML = toMMSS(elapsTime);

          break;
        }

        if (pos < window.trimPreviewParams[i][0]) {
          playerObject.seek(window.trimPreviewParams[i][0]);
          break;
        }

        if (pos > window.trimPreviewParams[i][1] && window.trimPreviewParams[i+1] && pos < window.trimPreviewParams[i+1][0]) {
          playerObject.seek(window.trimPreviewParams[i+1][0]);
          break;
        } else if (!window.trimPreviewParams[i+1]) {
          playerObject.pause();
          playerObject.seek(window.trimPreviewParams[0][0]);
        }
      }
    });

    function trimUpdate() {
      if (typeof window.currentTrimPin === 'number') {
        playerObject.seek(window.currentTrimPin);
        let lastTrimPin = window.trimPreviewParams[(window.trimPreviewParams.length - 1)][1];

        if (lastTrimPin <= window.currentTrimPin) {
          playerObject.pause();
        }
      }

      changeTimeInfo();
    }

    function changeTimeInfo() {
      mainPlayerCount = document.getElementById(data.player.id);
      let seek = mainPlayerCount.querySelector('.jw-slider-time');

      if (seek) {
        duration = 0;

        for (var i = 0; i < window.trimPreviewParams.length; i++) {
          duration += window.trimPreviewParams[i][1] - window.trimPreviewParams[i][0];
          startTimePoints.push(window.trimPreviewParams[i][0]);
        }

        durationTag = mainPlayerCount.querySelector('.jw-text-duration');
        olapsedTag = mainPlayerCount.querySelector('.jw-text-elapsed');
        progressTag = mainPlayerCount.querySelector('.jw-progress');
        knobTag = mainPlayerCount.querySelector('.jw-knob');

        if (seek) {
          seek.removeEventListener('click', seekEvent);
          seek.addEventListener('click', seekEvent);
        }

        durationTag.innerHTML = toMMSS(duration);
      }
    }

    function cloneTimeControlls() {
      mainPlayerCount = document.getElementById(data.player.id);
      let seek = mainPlayerCount.querySelector('.jw-slider-time');

      if (seek) {
        sliderTimeClone = mainPlayerCount.querySelector('.jw-slider-time').cloneNode(true);
        controlbar = mainPlayerCount.querySelector('.jw-controlbar');
        mainPlayerCount.querySelector('.jw-slider-time').remove();
        controlbar.insertBefore(sliderTimeClone, mainPlayerCount.querySelector('.jw-button-container'));

        elapsedClone = mainPlayerCount.querySelector('.jw-text-elapsed').cloneNode(true);
        buttonContainer = mainPlayerCount.querySelector('.jw-button-container');
        mainPlayerCount.querySelector('.jw-text-elapsed').remove();
        buttonContainer.insertBefore(elapsedClone, mainPlayerCount.querySelector('.jw-text-countdown'));
      }
    }

    function seekEvent(event) {
      let seekBar = mainPlayerCount.querySelector('.jw-slider-container');
      let widthСorrection = (this.offsetWidth - seekBar.offsetWidth) / 2;
      var rect = this.getBoundingClientRect();
      let left = event.clientX - rect.left - widthСorrection;
      let length = seekBar.offsetWidth;
      let persentLeft = (100*left)/length;
      let currentTime = (duration*persentLeft)/100;
      let intervalsSumm = 0;

      for (var i = 0; i < window.trimPreviewParams.length; i++) {
        let prewIntervalsSumm = intervalsSumm;
        intervalsSumm += (window.trimPreviewParams[i][1] - window.trimPreviewParams[i][0])

        if (intervalsSumm >= currentTime) {
          playerObject.seek(window.trimPreviewParams[i][0] + (currentTime - prewIntervalsSumm));
          break;
        }
      }
    };

    function toMMSS(number) {
      var sec_num = parseInt(number, 10);
      var hours   = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) { hours   = '0' + hours; }
      if (minutes < 10) { minutes = '0' + minutes; }
      if (seconds < 10) { seconds = '0' + seconds; }

      if (hours != '00') {
        return hours + ':' + minutes + ':' + seconds;
      } else {
        return minutes + ':' + seconds;
      }
    }

    function getClosest(array, value) {
      let closest;
      array.some(function (a) {
        if (a > value) {
          return true;
        }
        closest = a;
      });
      return closest;
    }
  };

  eventsForGA() {
    const data = this;

    // Video Plays
    if (typeof gtag !== 'undefined') {
      return false;
    }

    data.playerInstance.on('play', function(){
      gtag('event', 'Video Plays',{
        'event_category': "Video Player",
        'UserID': localStorage.user_id,
        'user_id': localStorage.user_id,
        'User Registration Type': localStorage.user_type,
        'nonInteraction': true
      });
    });
    // Video Completes
    data.playerInstance.on('complete', function(){
      gtag('event', 'Video Completes',{
        'event_category': "Video Player",
        'UserID': localStorage.user_id,
        'user_id': localStorage.user_id,
        'User Registration Type': localStorage.user_type,
        'nonInteraction': true
      });
    });
    // Buffer events
    data.playerInstance.on('buffer', function(){
      gtag('event', 'Buffer events',{
        'event_category': "Video Player",
        'UserID': localStorage.user_id,
        'user_id': localStorage.user_id,
        'User Registration Type': localStorage.user_type,
        'nonInteraction': true
      });
    });
    // Idle events
    data.playerInstance.on('idle', function(){
      gtag('event', 'Idle events',{
        'event_category': "Video Player",
        'UserID': localStorage.user_id,
        'user_id': localStorage.user_id,
        'User Registration Type': localStorage.user_type,
        'nonInteraction': true
      });
    });
    // Pause events
    data.playerInstance.on('pause', function(){
      gtag('event', 'Pause events',{
        'event_category': "Video Player",
        'UserID': localStorage.user_id,
        'user_id': localStorage.user_id,
        'User Registration Type': localStorage.user_type
      });
    });
    // Seek events
    data.playerInstance.on('seek', function(){
      gtag('event', 'Seek events',{
        'event_category': "Video Player",
        'UserID': localStorage.user_id,
        'user_id': localStorage.user_id,
        'User Registration Type': localStorage.user_type,
        'nonInteraction': true
      });
    });
    data.playerInstance.on('error', function(e){
      $.ajax({
        type: 'POST',
        url: '/video_playing_error',
        data: {
          video_id: window.campusreelVideoData.playing_video_id,
          error_code: e.code,
          browser: userBrowserInfo()
        }
      });
    });
  };

  initializeMinimizing() {
    const data = this;
    const playerContainerEl = document.querySelector('.video-player');
    const videoCounterElem = $(".video_counter").length;
    const counterIntroHeight = $(".video_counter .intro").height();
    const associatedPadding = 60;
    function getElementOffsetTop(el) {
      const boundingClientRect = el.getBoundingClientRect();
      const bodyEl = document.body;
      const docEl = document.documentElement;
      const scrollTop = window.pageYOffset || docEl.scrollTop || bodyEl.scrollTop;
      const clientTop = docEl.clientTop || bodyEl.clientTop || 0;
      return Math.round(boundingClientRect.top + scrollTop - clientTop);
    }
    function getScrollTop() {
      const docEl = document.documentElement;
      return (window.pageYOffset || docEl.scrollTop) - (docEl.clientTop || 0);
    }
    this.playerInstance.on('ready', function() {
      const utils = data.playerInstance.utils;
      let playerOffsetTop = getElementOffsetTop(playerContainerEl);
      if (data.playerInstance.id === 'top-video-player' || data.playerInstance.id === 'top-video-player-without-widget') {
        playerOffsetTop = playerOffsetTop + 200
      }
      function onScrollViewHandler() {
        const minimize = getScrollTop() >= playerOffsetTop;
        mobilePagesTopVideoAd();
        utils.toggleClass(playerContainerEl, 'player-minimize', minimize);
        data.playerInstance.resize();

        if (document.querySelector('#video-sticky-bottom-ad').length) {
          if (minimize) {
            document.querySelector('#video-sticky-bottom-ad').classList.add('fixed');
          } else {
            document.querySelector('#video-sticky-bottom-ad').classList.remove('fixed');
          }
        }
      }
      let isScrollTimeout = false;
      window.onscroll = function() {
        if (videoCounterElem) {
          document.querySelector(".player-minimize").style.cssText = `bottom: ${counterIntroHeight + associatedPadding}`;
        }
        if (isScrollTimeout) return;
        isScrollTimeout = true;
        onScrollViewHandler();
        isScrollTimeout = false;
      };
    });
  };


  mobilePagesTopVideoAd() {
    if ( ( (locationPathName('/compare-colleges/')) ||
           (locationPathName('/how-to-transfer-colleges')) ||
           (locationPathName('/how-to-get-into-college')) ) &&
           ( $(window).width() <= 720) ) {
      $('.player-minimize').css('top', '0');
      document.querySelector(".player-minimize").style.cssText = 'top: 0';
    }
  }

  playerListener() {
    const data = this;

    data.playerInstance.on('ready', function(){
      data.playerListenerComplete();
    });
  };

  _showControlBar() {
    const data = this
    data.playerInstance.on('userInactive', function(e) {
      const element = document.querySelector(".jw-flag-user-inactive")
      element.classList.remove("jw-flag-user-inactive")
    });
  }

  playerListenerComplete() {
    const data = this;

    data.playerInstance.on('complete', function() {
      // on desktop we don't have nav element so we take href from a second item from video list
      const url = document.querySelector('.video-navigation-item.nav-next').getAttribute('href') || (document.querySelector('.video-list').children[1] && document.querySelector('.video-list').children[1].href);
      if (url && (data.playerInstance.id !== 'top-video-player' || data.playerInstance.id !== 'top-video-player-without-widget')) {
        window.location.replace(url);
      }
    });
  };

  stopPlayer() {
    this.playerInstance.stop();
  };

  pausePlayer() {
    this.playerInstance.pause();
  };

  setFullscreen(){
    this.playerInstance.setFullscreen();
  };

  alwaysShowControllbar(show) {
    this.params.showControllbar = show
  }

  initializeDownloadBtn() {
    const data = this;
    const buttonId = 'download-video-button';
    const iconPath = '/assets/download.svg';
    const tooltipText = 'Download Video';
    data.playerInstance.addButton(iconPath, tooltipText, buttonClickAction, buttonId);

    function buttonClickAction() {
      const playlistItem = data.playerInstance.getPlaylistItem();
      const anchor = document.createElement('a');
      anchor.setAttribute('target', '_blank');
      const allSources = playlistItem['allSources'];
      const fileUrl = allSources[allSources.length - 1].file;
      anchor.setAttribute('href', fileUrl);
      anchor.setAttribute('download', fileUrl);
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }
}

export { VideoPlayer };
