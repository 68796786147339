import './home_page.scss';
import '../../../components/html/slick_slider/slick_slider';
import { WOW } from 'wowjs';
import { VideoPlayer } from 'components/html/jwplayer/video_player'

document.addEventListener('DOMContentLoaded', () => {  
  const pageWrapper = document.querySelector('.home_page');
  const blockUnderHeader = document.querySelector('.block_under_header');
  const navbarHeader = document.querySelector('.navbar');
  const announcingHeader = pageWrapper.querySelector('.home_page--announcing');
  const announcingClose = pageWrapper.querySelector('.home_page--announcing--close-btn');
  const videoBanner = pageWrapper.querySelector('.home_page--banner--row--column--video video');
  const videoModal = document.querySelector('.home_page--video-modal');

  document.addEventListener('initHomePageVideoModal', initVideoModal, false);

  if (videoBanner) {
    videoBanner.play();
  }

  if (announcingClose) {
    announcingClose.addEventListener('click', () => {
      announcingHeader.classList.add('hide');
      navbarHeader.classList.remove('announcing-header');
    });
  }

  if (blockUnderHeader) {
    blockUnderHeader.classList.add('hidden');
  }
  if (announcingHeader) {
    navbarHeader.classList.add('announcing-header');
    pageWrapper.classList.add('announcing-header');
  }

  $('.home_page--customers--slider--list').not('.slick-initialized').slick({
    slidesToShow: 3,
    arrows: true,
    draggable: true,
    centerMode: false,
    infinite: true,
    variableWidth: true,
    adaptiveHeight: true,
    slidesToScroll: 1,
    prevArrow: $('.home_page--customers--slider--controls--prev'),
    nextArrow: $('.home_page--customers--slider--controls--next'),
    responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        centerMode: true
      }
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        centerMode: true
      }
    }]
  });

  function handlePressSlider() {
    if (window.innerWidth <= 560) {
      $('.home_page--press--list').not('.slick-initialized').slick({
        slidesToShow: 1,
        arrows: true,
        draggable: true,
        centerMode: true,
        infinite: true,
        variableWidth: true,
        adaptiveHeight: true,
        slidesToScroll: 1,
        prevArrow: $('.home_page--press--list--controls--prev'),
        nextArrow: $('.home_page--press--list--controls--next'),
      });
    } else if ($('.home_page--press--list').hasClass('slick-initialized')) {
      $('.home_page--press--list').slick('unslick');
    }
  }
  
  handlePressSlider();
  window.addEventListener('resize', handlePressSlider);

  // init scroll animation
  let wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 100,
    mobile: true,
    live: true
  })
  wow.init();

  window.onclick = (e) => {
    const videoModal = document.querySelector('.home_page--video-modal');
    if (e.target === videoModal) {
      hideVideModal();
    }
  }

  function initVideoOpenPlayModalListener() {
    pageWrapper.querySelectorAll('.open-video-pop-up-js').forEach((el) => {
      el.addEventListener('click', (event) => {
        window.player = new VideoPlayer({
          id: 'popup-video',
          videoKey: event.target.dataset.videoKey,
          videoSources: JSON.parse(event.target.dataset.videos),
          autostart: true,
          controls: true,
          hideDownloadBtn: true,
          mute: false,
          awsSrc: event.target.dataset.file,
        })
        player.initialize();
        initVideoModal(event.target);
      });
    });
  }
  initVideoOpenPlayModalListener();

  function initVideoModal(videoEl) {
    const close = document.querySelector('.home_page--video-modal--content--controls--close');
    const button = document.querySelector('.home_page--video-modal--content--controls--button');
    const title = document.querySelector('.home_page--video-modal--content--title');
    const user_full_name = document.querySelector('.home_page--video-modal--content--bottom--person--text--name');
    const college_name = document.querySelector('.home_page--video-modal--content--bottom--person--text--position');
    const logo = document.querySelector('.home_page--video-modal--content--bottom--logo');
    const avatar = document.querySelector('.home_page--video-modal--content--bottom--person--avatar');
    const parentEl = videoEl.parentElement;

    logo.style.cssText = parentEl.querySelector('.home_page--customers--slider--list--item--bottom--logo').style.cssText;
    avatar.style.cssText = parentEl.querySelector('.home_page--customers--slider--list--item--bottom--person--avatar').style.cssText;
    user_full_name.innerText = parentEl.querySelector('.home_page--customers--slider--list--item--bottom--person--text--name').innerText;
    college_name.innerText = parentEl.querySelector('.home_page--customers--slider--list--item--bottom--person--text--position').innerText;
    title.innerText = `A Day in the Life at ${college_name.innerText}`;
    close.addEventListener('click', () => {
      hideVideModal();
    });

    button.addEventListener('click', () => {
      hideVideModal();
    });

    showVideoModal();
  }

  function hideVideModal() {
    videoModal.classList.remove('active');
    document.querySelector('body').style.overflow = 'initial';
    player.stopPlayer();
  }

  function showVideoModal() {
    disableScroll();
    videoModal.classList.add('active');
  }

  function disableScroll() {
    document.querySelector('body').style.overflow = 'hidden';
  }
});
